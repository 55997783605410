import React from "react";
import PageWrapper from '../components/PageWrapper';
import RegisterForm from "../components/User/RegisterForm";
import { Box } from "../components/Layout"
import colors from '../utils/colors';

const Register = () => (
  <PageWrapper>
    <Box bg={colors.primary}>
        <Box
        width={[1, 1, 1 / 2]}
        m={['3.5rem 0 5rem 0', '3.5rem 0 5rem 0', '3.5rem auto 5rem auto']}
        px={[3, 3, 0]}
        color={colors.secondary}
        >
      <RegisterForm />
      </Box></Box>    
  </PageWrapper>
)

export default Register
