import { CheckBox } from 'devextreme-react/check-box';
import Link from 'gatsby-link';
import React, { Component } from 'react';
import { ReCaptcha } from 'react-recaptcha-google'
import { Button } from 'devextreme-react';
import { Box, Flex } from '../Layout';
import { navigate } from '@reach/router';
import { useState} from "react"
import { isLoggedIn } from "../../utils/auth"
import { useFirebase } from "gatsby-plugin-firebase"
import Form from 'devextreme-react/form';
import { LoadPanel } from 'devextreme-react/load-panel';
import colors from '../../utils/colors';
import Logout from '../../components/Logout';



class RegisterFormBase extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {registerSuccess: false,
      registerButtonEnabled: false, 
      loadPanelVisible: false,
      acceptTerms: false,
    acceptSubscribe: true};
    this.setForm = (ref) => {
      if (ref != null)
          this.registerForm = ref.instance;
    };
    this.formItems = [
      {                    
        dataField: 'salutation',
        editorType: 'dxSelectBox',
        editorOptions: {
            dataSource: ['Mr.','Mrs.','Ms.']
        },
        validationRules:[{type:"required", message:"It is required"}],
        label: {text: 'Salutation'},
      },
      {                    
        dataField: 'firstName',
        validationRules:[{type:"required", message:"It is required"}],
        label: {text: 'First Name'},
      },
      {                    
        dataField: 'lastName',
        validationRules:[{type:"required", message:"It is required"}],
        label: {text: 'Last Name'},
      },
      {                    
        dataField: 'company',
        validationRules:[{type:"required", message:"It is required"}],
        label: {text: 'Company'},
      },
      {                    
        dataField: 'country',
        validationRules:[{type:"required", message:"It is required"}],
        label: {text: 'Country'},
      },
      {                    
        dataField: 'email',
        validationRules:[{type:"email", message:"Invalid email"},{type:"required", message:"It is required"}],
        label: {text: 'Email'},
      },

      {                    
        dataField: 'password',
        validationRules:[{type:"required", message:"It is required"}],
        editorOptions: {
          mode: "password"

        },
        label: {text: 'Password'},
      },
      {                    
        dataField: 'regPasswordReenter',
        validationRules:[{type:"required", message:"It is required"}, {type:"compare", comparisonTarget: () => {return this.registerForm.option('formData').password;}, message: "Password and Confirm Password do not match"}],
        editorOptions: {
          mode: "password"

        },
        label: {text: 'Confirm Password'},
      }
    ];

    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.onRegisterClick = this.onRegisterClick.bind(this);
    this.onRegisterSuccess = this.onRegisterSuccess.bind(this);
    this.onRegisterError = this.onRegisterError.bind(this);
    this.onAcceptTermsChanged = this.onAcceptTermsChanged.bind(this);
    this.onAcceptSubscribeChanged = this.onAcceptSubscribeChanged.bind(this);
  }

  onAcceptTermsChanged(args) {
    this.setState({
      acceptTerms: args.value
    });
  }
  onAcceptSubscribeChanged(args) {
    this.setState({
      acceptSubscribe: args.value
    });
  }
  componentDidMount() {
    if (this.captchaDemo) {
        //console.log("started, just a second...")
        this.captchaDemo.reset();
    }
    this.registerForm.focus();
  }
  onLoadRecaptcha() {
      if (this.captchaDemo) {
          this.captchaDemo.reset();
          this.recaptchaToken = null;
          this.setState({registerButtonEnabled: false});
      }
  }
  verifyCallback(recaptchaToken) {
    // Here you will get the final recaptchaToken!!!  
    //console.log(recaptchaToken, "<= your recaptcha token")
    if (recaptchaToken) {
      this.recaptchaToken = recaptchaToken;
      this.setState({registerButtonEnabled: true});
    }
  }
  onRegisterClick() {
    if (!this.registerForm.validate().isValid)
      return;
    if (!this.state.acceptTerms)
    {
      this.setState({ errorMsg: "Please accept the Linerlytica Terms of Service" });
      return;
    }

    this.setState({loadPanelVisible: true});
    let postApiUrl = `${process.env.GATSBY_USER_API_URL}/register`;    
    var request = new XMLHttpRequest();
    request.callObj = this;
    request.open('POST', postApiUrl,true);
    request.setRequestHeader('Content-Type','application/json; charset=utf-8');
    request.setRequestHeader('recaptchatoken',this.recaptchaToken);
    request.onreadystatechange = function (reposta) {
    if (this.readyState === 4) {
        //console.log(this.responseText);
        let body = JSON.parse(this.responseText);
        if (body.message)          
          this.callObj.onRegisterError(body.message);  
        else if (body['error-codes'] || !body.uid)
          this.callObj.onRegisterError();  
        else
          this.callObj.onRegisterSuccess(body.uid);            
    }
    };
    this.email = this.registerForm.option('formData').email;
    this.password = this.registerForm.option('formData').password;
    var data = this.registerForm.option('formData');
    data.emailSub = this.state.acceptSubscribe?'Y':'N';
    request.send(JSON.stringify(data));
  }

  onRegisterSuccess(uid) {
    const thisObj = this;
    this.props.firebase.auth()
      .signInWithEmailAndPassword(this.email, this.password)
      .then((result) => {
        result.user.sendEmailVerification()
        .then(function() {
          thisObj.props.firebase.auth().signOut().then(function() {
            thisObj.setState({loadPanelVisible: false, registerSuccess: true});
          });
        })
        .catch(function(error) {
          thisObj.setState({ errorMsg: error.message });
        });
      })
      .catch(error => {
        thisObj.setState({ errorMsg: error.message });
      });

  }
  onRegisterError(errMsg) {
    this.setState({loadPanelVisible: false});
    if (this.captchaDemo) {
      this.captchaDemo.reset();
    }
    this.recaptchaToken = null;
    this.setState({registerButtonEnabled: false});
    if (errMsg)
      this.setState({errorMsg: errMsg});
  }

  render() {
    if (this.state.registerSuccess) 
      return (
        <>
        <h2>Register Success!</h2>
        <p>A verification email has sent to your email box. <br/>
        Please follow the instruction in the email to verify your email. <br/>
        Check junkmail box in case you don't recieve it in 5 minutes. Thanks.</p>

        <p>You may <Link style={{color: colors.secondary}} to='/app/login'>login</Link> again after the email is verified</p>
        <Logout redirectUrl={"NONE"}/>
        </>
      )
    else 
      return (
      <>
      <h2>User Registration</h2>
      
      <p>Please register for 5-day trial account to the Linerlytica website.<br/>
Trial account can have full access to all functionalities with limits on data downloads.
</p>    

      <Box width={[1,'400px','400px']}>

      <Form
        colCount={1}
        items={this.formItems}
        ref={this.setForm}>
      </Form> 
      </Box>
      <br/>
      <CheckBox style={{fontSize: 17}} value={this.state.acceptTerms} onValueChanged={this.onAcceptTermsChanged} text={'I hereby confirm that I have read and accepted the '}></CheckBox><Link style={{fontSize: 17}} to="/terms">Terms of Service</Link><br/>
      <CheckBox style={{fontSize: 17}} value={this.state.acceptSubscribe} onValueChanged={this.onAcceptSubscribeChanged} text={'I agree to receive further information and research materials from Linerltyica. '}></CheckBox>
      <Box m={['20px 0 20px 0', '20px 0 20px 0', '20px auto 20px auto']}>
        <Flex wrap={'nowrap'}>
        {/* You can replace captchaDemo with any ref word */}
        <Box width={'50%'}>
        <ReCaptcha
            ref={(el) => {this.captchaDemo = el;}}
            size="normal"
            render="explicit"
            sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
            onloadCallback={this.onLoadRecaptcha}
            verifyCallback={this.verifyCallback}
        />
        </Box>
        <Box width={'50%'}>
        <Button
          width={120}
          height={76}
          text="Register"
          icon="card"
          onClick={this.onRegisterClick}
          disabled={!this.state.registerButtonEnabled}
        />
        </Box>
        </Flex>
      </Box>
      
      {this.state.errorMsg && <p>{this.state.errorMsg}</p>}
      <p>Check <Link style={{color: colors.secondary}} to="/subscription">Subscription Plans here</Link></p>  
      <LoadPanel visible={this.state.loadPanelVisible}/>
      </>
    );
  };
};


const RegisterForm = () => {
  const [firebase, setFirebase] = useState();

  useFirebase(firebase => {
    setFirebase(firebase);
  }, [])

  if (isLoggedIn()) {
    navigate(`/`)
  }

  return (
    
    <RegisterFormBase firebase={firebase} />

  );

}

export default RegisterForm;